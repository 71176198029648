<!-- 快捷定位 -->
<template>
  <el-breadcrumb class="breadcrumb" separator-class="el-icon-arrow-right">
    <el-breadcrumb-item
      :to="item.path"
      v-for="item in breadcrumbs"
      :key="item.name"
      >{{ item.name }}</el-breadcrumb-item
    >
  </el-breadcrumb>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
