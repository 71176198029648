<!-- 报名入口 -->
<template>
  <div class="frame">
    <div class="box" v-if="!showProject">
      <div class="in">
        <div class="title">报名系统</div>
        <div class="tip">请先填写您的基本信息</div>
        <el-form
          ref="form"
          :model="queryParams"
          :rules="queryRules"
          label-width="80px"
        >
          <el-form-item label="身份证" prop="idNo" required>
            <el-input v-model="queryParams.idNo"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="linkPhone" required>
            <el-input v-model="queryParams.linkPhone" type="number"></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="smsCode">
            <div class="same">
              <el-input
                v-model="queryParams.smsCode"
                placeholder="请输入验证码"
                type="number"
              ></el-input>
              <el-button
                type="primary"
                :disabled="cd != maxCd"
                @click="sendCode"
                >{{ codeText }}</el-button
              >
            </div>
          </el-form-item>
          <div class="btn">
            <el-button type="primary" @click="startSearch">开始报名</el-button>
          </div>
        </el-form>
      </div>
    </div>
    <div class="project-box" v-else>
      <UserInfo />
      <div class="list">
        <div class="item" v-for="item in projectList" :key="item.id">
          <div class="title">{{ item.projectName }}</div>
          <div class="desc">{{ item.projectDesc }}</div>
          <div class="info">
            <p>报名开始时间：{{ item.startTime }}</p>
            <p>报名截止时间：{{ item.endTime }}</p>
            <!-- <p>
              <span>初试人数：{{ item.firstExambodyTotalnum }}</span>
              <span>复试人数：{{ item.firstExambodyPassnum }}</span>
              <span>进面人数：{{ item.secondExambodyPassnum }}</span>
              <span>录用人数：{{ item.employbodyNum }}</span>
            </p> -->
          </div>
          <el-button size="mini" type="info" disabled v-if="!item.isStart"
            >报名未开始</el-button
          >
          <el-button size="mini" type="info" disabled v-else-if="item.isPast"
            >报名已截止</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="navToApply(item)"
            v-else-if="!item.isSubmit"
            >开始报名</el-button
          >
          <el-button
            size="mini"
            type="success"
            v-else
            @click="searchScore(item)"
            >查看进度</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Dialog } from "vant";
import UserInfo from "../components/userInfo.vue";
export default {
  data() {
    const idNoValid = (r, v, cb) => {
      const validReg =
        /^\d{6}((((((19|20)\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|(((19|20)\d{2})(0[13578]|1[02])31)|((19|20)\d{2})02(0[1-9]|1\d|2[0-8])|((((19|20)([13579][26]|[2468][048]|0[48]))|(2000))0229))\d{3})|((((\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|((\d{2})(0[13578]|1[02])31)|((\d{2})02(0[1-9]|1\d|2[0-8]))|(([13579][26]|[2468][048]|0[048])0229))\d{2}))(\d|X|x)$/;
      if (!v || !validReg.test(v)) {
        cb("请填写正确的身份证号");
        return;
      }
      cb();
    };
    const phoneValid = (r, v, cb) => {
      const validReg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
      if (!v || !validReg.test(v)) {
        cb("请填写正确的手机号");
        return;
      }
      cb();
    };
    return {
      queryParams: {
        linkPhone: "",
        idNo: "",
        smsCode: "",
      },
      queryRules: {
        linkPhone: {
          validator: phoneValid,
          trigger: "blur",
        },
        idNo: {
          validator: idNoValid,
          trigger: "blur",
        },
        smsCode: {
          required: true,
          message: "请输入验证码",
          trigger: "blur",
        },
      },
      cd: 0,
      maxCd: 60, // 倒计时总数
      code: "", // 验证码
      codeText: "发送验证码", // 提示信息
      timer: null,
      projectList: [],
      showProject: false, // 是否展示考试项目
    };
  },
  components: { UserInfo },
  created() {
    this.cd = this.maxCd;
    if (sessionStorage.getItem("token")) {
      // 若存在用户信息，直接请求数据
      this.getList();
    }
  },
  mounted() {},
  methods: {
    // 发送短信验证码
    async sendCode() {
      if (this.cd != this.maxCd) return;
      let res = await this.$api.sendCode(this.queryParams.linkPhone);
      if (res.code != 0) return;
      this.$toast.success("发送成功!");
      this.computedCd(); // 先执行一次
      this.timer = setInterval(this.computedCd, 1000);
    },
    // 倒计时计算函数
    computedCd() {
      if (this.cd <= 0) {
        this.codeText = "重新获取";
        clearInterval(this.timer);
        this.cd = this.maxCd;
        return;
      }
      this.cd--;
      this.codeText = this.cd + "s后重新获取";
    },
    // 先验证验证码，再查询考试项目
    startSearch() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let res = await this.$api.validCode(this.queryParams);
          if (res.code != 0) return;
          this.$toast.success("登录成功!");
          // 缓存用户数据
          sessionStorage.setItem("token", res.data.token);
          const idNo = res.data.idNo.replace(
            /(?<=\d{4})\d{11}(?=\d{2})/,
            "***********"
          );
          sessionStorage.setItem("userInfo", idNo);
          this.getList();
        }
      });
    },
    async getList() {
      this.showProject = true;
      const nowTime = new Date();
      let res = await this.$api.getProjectList();
      this.projectList = res.data.map((ele) => {
        ele.isStart = nowTime >= new Date(ele.startTime); // 判断报名是否开始
        ele.isPast = nowTime > new Date(ele.endTime); // 判断报名是否已截止
        ele.isSubmit = false; // 默认项目未参加
        return ele;
      });
    },
    async navToApply(item) {
      let res = await this.$api.validIsHad({ projectId: item.id });
      if (res.data) {
        // 若已报名
        item.isSubmit = true;
        Dialog.confirm({
          title: "提示",
          message: "当前项目您已参加，是否前往查看进度",
          confirmButtonText: "前往查看",
        })
          .then(() => {
            this.$router.push("/score?id=" + item.id);
          })
          .catch(() => {
            console.log("取消");
          });
        return;
      }
      this.$router.push("/applyForm?id=" + item.id);
    },
    // 查看成绩进度
    searchScore(row) {
      this.$router.push("/score?id=" + row.id);
    },
  },
  beforeDestroy() {
    this.timer && clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
:deep(.red) {
  color: #f56c6c;

  &:hover {
    color: #f78989;
  }
}
.frame {
  min-height: calc(100vh - 316px);
  margin: 20px 0;
  position: relative;
}
.box {
  width: 550px;
  padding: 20px 0;
  border-radius: 10px;
  box-shadow: 0 0 5px #ddd;
  position: absolute;
  left: 50%;
  top: 15%;
  transform: translateX(-50%);
  background-color: #fff;

  .in {
    width: 360px;
    margin: 0 auto;
  }
  .title {
    font-size: 24px;
    margin-bottom: 5px;
    text-align: center;
    font-weight: bold;
  }
  .tip {
    color: #999;
    font-size: 15px;
    text-align: center;
    margin-bottom: 15px;
  }
  .same {
    width: 100%;
    display: flex;
  }
  .btn {
    text-align: center;
  }
}
.project-box {
  width: 1200px;
  margin: 0 auto;

  .userinfo {
    margin-bottom: 10px;
    text-align: right;
    font-size: 14px;

    span {
      margin-right: 5px;
    }
  }

  .list {
    .item {
      width: 100%;
      padding: 20px;
      box-sizing: border-box;
      background-color: #fff;
      box-shadow: 0 0 5px #eee;
      border-radius: 10px;
      text-align: center;

      .title {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 10px;
      }
      .info {
        font-size: 14px;
        color: #666;
        margin-bottom: 10px;
        line-height: 2;

        span + span {
          margin-left: 20px;
        }
      }
      .desc {
        padding: 0 20px;
        margin-bottom: 10px;
      }

      & + .item {
        margin-top: 10px;
      }
    }
  }
}

@media (max-width: 750px) {
  .frame {
    background-color: #fff;
    min-height: calc(100vh - 90px);
    margin: 50px 0 10px;

    .box {
      width: 100%;
      padding: 20px;
      box-sizing: border-box;
      position: static;
      transform: translate(0);
      box-shadow: none;

      .in {
        width: 100%;
      }
    }
  }
  .project-box {
    width: 100%;

    .userinfo {
      padding-top: 10px;

      span {
        margin-right: 10px;
      }
    }
    .list {
      padding: 0 10px;
      box-sizing: border-box;
    }
  }
}
</style>
