<!-- 考试项目填报页面 -->
<template>
  <div class="frame">
    <van-dialog
      v-model="educationShow"
      title="新增教育经历"
      show-cancel-button
      @confirm="confirmEdu"
    >
      <van-form ref="eduForm" validate-first>
        <van-field
          v-model="editEducation.schoolName"
          name="schoolName"
          label="学校名称"
          placeholder="学校名称"
          :rules="[{ required: true, message: '请输入学校名称' }]"
        />
        <van-field
          v-model="editEducation.majorStudied"
          name="majorStudied"
          label="所学专业"
          placeholder="所学专业"
          :rules="[{ required: true, message: '请输入所学专业' }]"
        />
        <van-field
          v-model="editEducation.degree"
          name="degree"
          readonly
          label="学历"
          placeholder="学历"
          :rules="[{ required: true, message: '请选择学历' }]"
          @click="degreeShow = true"
        />
        <van-popup v-model="degreeShow" position="bottom">
          <van-picker
            show-toolbar
            :columns="degrees"
            @confirm="confirmDegree"
            @cancel="degreeShow = false"
          />
        </van-popup>
        <van-field
          v-model="editEducation.academicDegree"
          name="academicDegree"
          readonly
          label="学位"
          placeholder="学位"
          :rules="[{ required: true, message: '请选择学位' }]"
          @click="academicDegreeShow = true"
        />
        <van-popup v-model="academicDegreeShow" position="bottom">
          <van-picker
            show-toolbar
            :columns="academicDegrees"
            @confirm="confirmAcademicDegree"
            @cancel="academicDegreeShow = false"
          />
        </van-popup>
        <van-field
          name="educationBetweenTime"
          readonly
          clickable
          :value="editEducation.educationBetweenTime"
          label="在校时间"
          placeholder="在校时间范围"
          @click="eduTimeShow = true"
          :rules="[{ required: true, message: '请选择在校时间' }]"
        />
      </van-form>
    </van-dialog>
    <van-calendar
      type="range"
      v-model="eduTimeShow"
      :min-date="minDate"
      @confirm="eduTimeConfirm"
    />
    <van-dialog
      v-model="workShow"
      title="添加工作经历"
      show-cancel-button
      @confirm="confirmWork"
    >
      <van-form ref="workForm" validate-first>
        <van-field
          v-model="editWork.experienceCompanyName"
          name="experienceCompanyName"
          label="公司名称"
          placeholder="公司名称"
          :rules="[{ required: true, message: '请输入公司名称' }]"
        />
        <van-field
          v-model="editWork.station"
          name="station"
          label="岗位"
          placeholder="岗位"
          :rules="[{ required: true, message: '请输入岗位' }]"
        />
        <van-field
          name="workingBetweenTime"
          readonly
          clickable
          :value="editWork.workingBetweenTime"
          label="工作日期"
          placeholder="工作日期范围"
          @click="workTimeShow = true"
          :rules="[{ required: true, message: '请选择工作日期' }]"
        />
        <van-field
          name="jobResponsibilities"
          v-model="editWork.jobResponsibilities"
          rows="2"
          autosize
          label="主要职责"
          type="textarea"
          maxlength="50"
          placeholder="请输入主要职责"
          show-word-limit
          :rules="[{ required: true, message: '请输入主要职责' }]"
        />
      </van-form>
    </van-dialog>
    <van-calendar
      type="range"
      v-model="workTimeShow"
      :min-date="minDate"
      @confirm="workTimeConfirm"
    />
    <div class="content">
      <div class="title">填写报名信息</div>
      <el-form
        ref="form"
        :model="addParams"
        :rules="addRules"
        label-width="120px"
      >
        <el-form-item label="报名项目" prop="projectName">
          <span class="under">{{ addParams.projectName }}</span>
        </el-form-item>
        <div class="step">个人信息</div>
        <el-form-item label="姓名" prop="name">
          <el-input
            class="ipt"
            v-model="addParams.name"
            placeholder="请输入您的姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="出生年月" prop="birth">
          <el-date-picker
            v-model="addParams.birth"
            type="date"
            placeholder="请选择您的出生年月"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="性别" prop="sex" placeholder="请选择您的性别">
          <el-select v-model="addParams.sex">
            <el-option label="男" value="男"></el-option>
            <el-option label="女" value="女"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="民族" prop="ethnic">
          <el-select v-model="addParams.ethnic" placeholder="请选择您的民族">
            <el-option
              v-for="item in nations"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="现居住地" prop="currentResidence">
          <el-cascader
            v-model="addrs"
            placeholder="请选择"
            :options="areaList"
            :props="{ value: 'name', label: 'name' }"
            clearable
            @change="storeChange"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="身份证号" prop="idNo">
          <el-input
            class="ipt"
            v-model="addParams.idNo"
            placeholder="请输入您的身份证号"
            maxlength="18"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="linkPhone">
          <el-input
            class="ipt"
            v-model="addParams.linkPhone"
            placeholder="请输入您的联系电话"
            maxlength="11"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input
            class="ipt"
            type="email"
            v-model="addParams.email"
            placeholder="请输入您的邮箱"
          ></el-input>
        </el-form-item>
        <el-form-item label="婚姻状况" prop="maritalStatus">
          <el-select v-model="addParams.maritalStatus">
            <el-option label="未婚" value="未婚"></el-option>
            <el-option label="已婚" value="已婚"></el-option>
            <el-option label="丧偶" value="丧偶"></el-option>
            <el-option label="离婚" value="离婚"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="政治面貌" prop="politicalProfile">
          <el-select v-model="addParams.politicalProfile">
            <el-option
              v-for="item in political"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="工作年限" prop="workingSeniority">
          <el-input-number
            v-model="addParams.workingSeniority"
            :min="1"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="最高学历" prop="education">
          <el-select v-model="addParams.education" placeholder="请选择">
            <el-option
              v-for="item in educations"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="毕业院校" prop="graduateSchool">
          <el-input
            class="ipt"
            v-model="addParams.graduateSchool"
            placeholder="请输入您的毕业院校"
          ></el-input>
        </el-form-item>
        <el-form-item label="所学专业" prop="specialization">
          <el-input
            class="ipt"
            v-model="addParams.specialization"
            placeholder="请输入您的所学专业"
          ></el-input>
        </el-form-item>
        <el-form-item label="毕业时间" prop="graduationTime">
          <el-date-picker
            v-model="addParams.graduationTime"
            type="date"
            placeholder="请选择您的毕业时间"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item> -->
        <el-form-item label="职称" prop="position">
          <el-input
            class="ipt"
            v-model="addParams.position"
            placeholder="请输入您的职称"
          ></el-input>
        </el-form-item>
        <el-form-item label="个人简历" prop="personalResume">
          <el-input
            class="ipt"
            type="textarea"
            rows="6"
            maxlength="500"
            show-word-limit
            v-model="addParams.personalResume"
            placeholder="请输入您的个人简历"
          ></el-input>
        </el-form-item>
        <div class="step">求职意向</div>
        <el-form-item label="现月薪(税前)" prop="currentMonthlySalary">
          <el-input
            class="ipt"
            v-model="addParams.currentMonthlySalary"
            placeholder="请输入您的现月薪(税前)"
          ></el-input>
        </el-form-item>
        <el-form-item label="期望月薪(税前)" prop="expectedSalary">
          <el-input
            class="ipt"
            v-model="addParams.expectedSalary"
            placeholder="请输入您的期望月薪(税前)"
          ></el-input>
        </el-form-item>
        <el-form-item label="到岗时间" prop="onDutyTime">
          <el-input
            class="ipt"
            v-model="addParams.onDutyTime"
            placeholder="请输入您的到岗时间"
          ></el-input>
        </el-form-item>
        <div class="step">教育经历</div>
        <div class="table">
          <div
            class="tr"
            v-for="(item, idx) in educationData"
            :key="item.schoolName"
          >
            <div class="left">
              <div class="name">{{ item.schoolName }}</div>
              <div class="type">{{ item.majorStudied }}</div>
              <div class="type">{{ item.degree }}</div>
              <div class="type">{{ item.educationBetweenTime }}</div>
              <div class="td full">
                <div class="label">学位</div>
                <div class="val">{{ item.academicDegree }}</div>
              </div>
            </div>
            <div class="right">
              <i class="del el-icon-delete" @click="delEduItem(idx)"></i>
            </div>
          </div>
        </div>
        <div class="btn">
          <el-button size="mini" icon="el-icon-plus" @click="openEducation"
            >添加教育经历</el-button
          >
        </div>
        <div class="step">工作经历</div>
        <div class="table">
          <div
            class="tr"
            v-for="(item, idx) in workData"
            :key="item.schoolName"
          >
            <div class="left">
              <div class="name">{{ item.experienceCompanyName }}</div>
              <div class="type">{{ item.station }}</div>
              <div class="type">{{ item.workingBetweenTime }}</div>
              <div class="td full">
                <div class="label">工作职责</div>
                <div class="val desc">{{ item.jobResponsibilities }}</div>
              </div>
            </div>
            <div class="right">
              <i class="del el-icon-delete" @click="delWorkItem(idx)"></i>
            </div>
          </div>
        </div>
        <div class="btn">
          <el-button size="mini" icon="el-icon-plus" @click="openWork"
            >添加工作经历</el-button
          >
        </div>
        <div class="btn">
          <el-button type="primary" @click="startSubmit">提交信息</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { Dialog } from "vant";
import { URL, uploadURL } from "../util/config";
import areaList from "../util/area_list.json";
export default {
  data() {
    const idNoValid = (r, v, cb) => {
      const validReg =
        /^\d{6}((((((19|20)\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|(((19|20)\d{2})(0[13578]|1[02])31)|((19|20)\d{2})02(0[1-9]|1\d|2[0-8])|((((19|20)([13579][26]|[2468][048]|0[48]))|(2000))0229))\d{3})|((((\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|((\d{2})(0[13578]|1[02])31)|((\d{2})02(0[1-9]|1\d|2[0-8]))|(([13579][26]|[2468][048]|0[048])0229))\d{2}))(\d|X|x)$/;
      if (!v || !validReg.test(v)) {
        cb("请填写正确的身份证信息");
        return;
      }
      cb();
    };
    const phoneValid = (r, v, cb) => {
      const validReg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
      if (!v || !validReg.test(v)) {
        cb("请填写正确的手机号");
        return;
      }
      cb();
    };
    return {
      URL,
      uploadURL,
      project: {},
      addParams: {
        projectId: "",
        projectName: "",
        name: "",
        age: "",
        birth: "",
        email: "",
        ethnic: "",
        sex: "",
        idNo: "",
        linkPhone: "",
        maritalStatus: "",
        politicalProfile: "",
        graduateSchool: "",
        specialization: "",
        graduationTime: "",
        resumeUrl: "",
        position: "",
        personalResume: "",
        currentResidence: "",
        workingSeniority: 1,
        education: "",
        educationalExperienceList: [],
        workExperienceList: [],
      },
      addRules: {
        specialization: {
          required: true,
          message: "请输入您的专业",
          trigger: "blur",
        },
        name: {
          required: true,
          message: "请输入您的姓名",
          trigger: "blur",
        },
        age: {
          required: true,
          message: "请输入您的年龄",
          trigger: "blur",
        },
        birth: {
          required: true,
          message: "请选择您的出生年月",
          trigger: "blur",
        },
        email: {
          required: true,
          message: "请输入您的邮箱",
          trigger: "blur",
        },
        ethnic: {
          required: true,
          message: "请选择您的民族",
          trigger: "change",
        },
        sex: {
          required: true,
          message: "请选择您的性别",
          trigger: "change",
        },
        idNo: {
          validator: idNoValid,
          trigger: "blur",
        },
        linkPhone: {
          validator: phoneValid,
          trigger: "blur",
        },
        maritalStatus: {
          required: true,
          message: "请选择您的婚姻状况",
          trigger: "change",
        },
        politicalProfile: {
          required: true,
          message: "请选择您的政治面貌",
          trigger: "change",
        },
        education: {
          required: true,
          message: "请选择您的学历",
          trigger: "change",
        },
        graduateSchool: {
          required: true,
          message: "请输入您的毕业院校",
          trigger: "blur",
        },
        graduationTime: {
          required: true,
          message: "请选择您的毕业时间",
          trigger: "change",
        },
        position: {
          required: true,
          message: "请输入您的职称",
          trigger: "blur",
        },
        personalResume: {
          required: true,
          message: "请输入个人简历",
          trigger: "blur",
        },
        currentResidence: {
          required: true,
          message: "请选择现居住地",
          trigger: "blur",
        },
        currentMonthlySalary: {
          required: true,
          message: "请输入您的现月薪(税前)",
          trigger: "blur",
        },
        expectedSalary: {
          required: true,
          message: "请输入您的期望月薪(税前)",
          trigger: "blur",
        },
        onDutyTime: {
          required: true,
          message: "请输入您的到岗时间",
          trigger: "blur",
        },
      },
      fileList: [],
      nations: [
        "汉族",
        "壮族",
        "藏族",
        "裕固族",
        "彝族",
        "瑶族",
        "锡伯族",
        "乌孜别克族",
        "维吾尔族",
        "佤族",
        "土家族",
        "土族",
        "塔塔尔族",
        "塔吉克族",
        "水族",
        "畲族",
        "撒拉族",
        "羌族",
        "普米族",
        "怒族",
        "纳西族",
        "仫佬族",
        "苗族",
        "蒙古族",
        "门巴族",
        "毛南族",
        "满族",
        "珞巴族",
        "僳僳族",
        "黎族",
        "拉祜族",
        "柯尔克孜族",
        "景颇族",
        "京族",
        "基诺族",
        "回族",
        "赫哲族",
        "哈萨克族",
        "哈尼族",
        "仡佬族",
        "高山族",
        "鄂温克族",
        "俄罗斯族",
        "鄂伦春族",
        "独龙族",
        "东乡族",
        "侗族",
        "德昂族",
        "傣族",
        "达斡尔族",
        "朝鲜族",
        "布依族",
        "布朗族",
        "保安族",
        "白族",
        "阿昌族",
      ], // 56民族
      political: [
        "群众",
        "中共党员",
        "中共预备党员",
        "共青团员",
        "民革党员",
        "民盟盟员",
        "民建会员",
        "民进会员",
        "农工党党员",
        "致公党党员",
        "九三学社社员",
        "台盟盟员",
        "无党派人士",
      ], // 政治面貌
      educations: ["大专", "本科", "硕士", "博士", "其他"], // 学历
      areaList: areaList,
      addrs: [], // 已选地址
      timeBetween: [], // 所选时间段
      educationShow: false,
      editEducation: {
        schoolName: "",
        majorStudied: "",
        degree: "",
        educationBetweenTime: "",
        academicDegree: "",
      }, // 教育经历编辑参数
      educationData: [], // 教育经历
      workShow: false,
      editWork: {
        experienceCompanyName: "",
        station: "",
        workingBetweenTime: "",
        jobResponsibilities: "",
      }, // 工作经历编辑参数
      workData: [], // 工作经历
      workTimeShow: false,
      eduTimeShow: false,
      minDate: new Date(1960, 0, 1),
      degreeShow: false,
      degrees: ["中专", "高中", "大专", "本科", "研究生"], // 学历可选数据
      academicDegreeShow: false,
      academicDegrees: ["无", "学士", "硕士", "博士"], // 学位可选数据
    };
  },
  created() {
    // 前置判断
    // if (!this.$route.query.id || !sessionStorage.getItem("token")) {
    //   this.$router.replace("/apply");
    //   return;
    // }
    this.addParams.projectId = this.$route.query.id;
    this.getProjectInfo();
  },
  mounted() {},
  methods: {
    async getProjectInfo() {
      const params = {
        projectId: this.addParams.projectId,
      };
      let res = await this.$api.getProjectList(params);
      this.project = res.data[0] || {};
      this.addParams.projectName = this.project.projectName;
    },
    // 上传成功
    uploadSuc(res) {
      if (res.code != 0) {
        this.fileList = [];
        return;
      }
      this.$toast.success("上传成功!");
      this.addParams.resumeUrl = res.data;
    },
    uploadRemove() {
      this.fileList = [];
      this.addParams.resumeUrl = "";
    },
    storeChange(val) {
      if (val.length != 0) {
        this.addParams.currentResidence = val[0] + "/" + val[1];
      } else {
        this.addParams.currentResidence = "";
      }
      this.storeShow = false;
    },
    // 打开教育经历弹窗
    openEducation() {
      this.educationShow = true;
    },
    timeChange(val) {
      if (val) {
        this.editEducation.educationBetweenTime = val[0] + "-" + val[1];
        return;
      }
      this.editEducation.educationBetweenTime = "";
    },
    // 取消新增/编辑
    cancel() {
      this.timeBetween = [];
      Object.assign(
        this.$data.editEducation,
        this.$options.data().editEducation
      );
      Object.assign(this.$data.editWork, this.$options.data().editWork);
      this.educationShow = false;
      this.workShow = false;
      this.$forceUpdate();
    },
    // 删除教育经历
    delEduItem(idx) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确认删除当前信息?",
        })
        .then(() => {
          this.educationData.splice(idx, 1);
        })
        .catch(() => {});
    },
    openWork() {
      this.workShow = true;
    },
    // 在校日期确认
    eduTimeConfirm(arr) {
      const start = arr[0];
      const startDate =
        start.getFullYear() +
        "." +
        (start.getMonth() + 1) +
        "." +
        start.getDate();
      const end = arr[1];
      const endDate =
        end.getFullYear() + "." + (end.getMonth() + 1) + "." + end.getDate();
      this.editEducation.educationBetweenTime = startDate + "-" + endDate;
      this.eduTimeShow = false;
    },
    // 学历确认
    confirmDegree(val) {
      this.editEducation.degree = val;
      this.degreeShow = false;
    },
    // 学位确认
    confirmAcademicDegree(val) {
      this.editEducation.academicDegree = val;
      this.academicDegreeShow = false;
    },
    // 单个教育经历新增/编辑确认
    confirmEdu() {
      this.$refs.eduForm
        .validate()
        .then(() => {
          this.educationData.push({ ...this.editEducation });
          this.cancel();
        })
        .catch((err) => {
          console.log(err);
          this.educationShow = true;
        });
    },
    // 工作日期确认
    workTimeConfirm(arr) {
      const start = arr[0];
      const startDate =
        start.getFullYear() +
        "." +
        (start.getMonth() + 1) +
        "." +
        start.getDate();
      const end = arr[1];
      const endDate =
        end.getFullYear() + "." + (end.getMonth() + 1) + "." + end.getDate();
      this.editWork.workingBetweenTime = startDate + "-" + endDate;
      this.workTimeShow = false;
    },
    // 单个工作经历新增/编辑确认
    confirmWork() {
      this.$refs.workForm
        .validate()
        .then(() => {
          this.workData.push({ ...this.editWork });
          console.log(1);
          this.cancel();
        })
        .catch((err) => {
          console.log(err);
          this.workShow = true;
        });
    },
    // 删除单个工作经历
    delWorkItem(idx) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确认删除当前信息?",
        })
        .then(() => {
          this.workData.splice(idx, 1);
        })
        .catch(() => {});
    },
    // 提交前提示
    startSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.educationData.length == 0 || this.workData.length == 0) {
            this.$toast("教育/工作经历不能为空");
            return;
          }
          this.addParams.educationalExperienceList = this.educationData;
          this.addParams.workExperienceList = this.workData;

          Dialog.confirm({
            title: "温馨提示",
            message: "提交前请仔细核对报名内容,是否继续？",
            confirmButtonText: "我已核对,继续",
          })
            .then(() => {
              this.submit();
            })
            .catch(() => {
              console.log("取消");
            });
        }
      });
    },
    // 提交
    submit() {
      this.$api.submitApply(this.addParams).then((res) => {
        if (res.code == 0) {
          Object.assign(this.$data.addParams, this.$options.data().addParams);
          this.educationData = [];
          this.workData = [];
          Dialog.alert({
            title: "提交成功!",
            message: "可前往“信息查询”处查询报名进度",
          }).then(() => {
            this.$router.push("/apply");
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.frame {
  margin: 20px 0;

  .content {
    width: 1000px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 5px #eee;

    .title {
      font-size: 24px;
      text-align: center;
      font-weight: bold;
      margin-bottom: 10px;
    }
    .step {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px;
      padding-left: 10px;
      position: relative;

      &::after {
        content: "";
        display: block;
        width: 3px;
        height: 60%;
        border-radius: 20px;
        background-color: $theme;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .table {
      .tr {
        display: flex;
        align-items: center;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        padding: 8px;
        border-radius: 5px;
        box-sizing: border-box;

        & + .tr {
          margin-top: 10px;
        }

        .left {
          flex-grow: 1;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        }

        .td {
          display: flex;
          font-size: 14px;
          margin-top: 5px;
          font-size: 13px;
          line-height: 1.7;

          .label {
            width: 60px;
            text-align: right;
            margin-right: 15px;
            flex-shrink: 0;
          }
          .desc {
            white-space: pre-wrap;
          }
        }
        .name {
          font-size: 15px;
          font-weight: bold;
          margin-right: 20px;
        }
        .type {
          font-size: 14px;
          padding: 0 10px;
          position: relative;

          &::after {
            content: "";
            display: block;
            width: 1px;
            height: 50%;
            background-color: #eee;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        .full {
          width: 100%;
        }
        .right {
          margin-left: 15px;
          padding-right: 10px;
        }
        .del {
          cursor: pointer;

          &:hover {
            color: $theme;
          }
          &:active {
            transform: scale(0.9);
          }
        }
      }
    }
    .under {
      color: $theme;
      font-size: 18px;
      font-weight: bold;
    }
    .btn {
      text-align: center;
      margin-top: 20px;
    }
    .el-form {
      width: 600px;
      margin: 0 auto;
    }
    .ipt {
      width: 400px;
    }
  }
}

@media (max-width: 750px) {
  .frame {
    min-height: calc(100vh - 316px);
    margin: 50px 0 20px;
    position: relative;

    .content {
      width: 100%;

      .el-form {
        width: 100%;
      }
      .ipt {
        width: 100%;
      }
    }
  }
}
</style>
