// 引入axios
import axios from "axios";
import { Toast } from "vant";
import { ip } from "./config";

// 创建axios实例
const service = axios.create({
  baseURL: ip, // api的base_url  Vue项目可以根据 process.env.BASE_API，React可以在这里定义
  timeout: 1000000, // 请求超时时间
  withCredentials: false, // 跨域携带cookie
  headers: {
    "Content-Type": "application/json",
  },
});

// 添加请求拦截器，这里面可以配置一下每次请求都需要携带的参数，比如 token，timestamp等等，根据项目自己配置
service.interceptors.request.use(
  function (configs) {
    // token
    configs.headers["token"] = sessionStorage.getItem("token");
    return configs;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器 ，这里的 response是接收服务器返回后的结果，也可以在这里做一些状态判断
service.interceptors.response.use(
  (response) => {
    /**
     * 判断服务器请求是否成功
     * @method if
     * @param  {[type]} response [description]
     * @return {[type]}          [description]
     */
    if (![200, 201].includes(response.status)) {
      return Promise.reject(new Error("网络异常，请稍后重试"));
    } else {
      if (response.data.code != 0) {
        Toast(response.data.msg);
      }
      return response.data;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);
// 提供axios给外部调用
export default service;
