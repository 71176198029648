import request from "./request";

// 首页多个新闻查询
export function getHomeNews(data) {
  return request({
    url: `talent/sys-news/front/getNewByCode`,
    method: "post",
    data,
  });
}
// 新闻查询
export function getNewsList(params) {
  return request({
    url: `talent/sys-news/front`,
    method: "get",
    params,
  });
}
// 新闻详情
export function getNewDetail(id) {
  return request({
    url: `talent/sys-news/front/${id}`,
    method: "get",
  });
}
// 考试项目查询
export function getProjectList(params) {
  return request({
    url: `talent/examination-project/front`,
    method: "get",
    params,
  });
}
// 发送验证码
export function sendCode(phoneNum) {
  return request({
    url: `talent/enrollment/front/sendMessage?phoneNum=` + phoneNum,
    method: "post",
  });
}
// 验证登录信息-验证码
export function validCode(data) {
  return request({
    url: `talent/enrollment/front/checkSmsCode`,
    method: "post",
    data,
  });
}
// 验证用户是否已经报名该项目
export function validIsHad(params) {
  return request({
    url: `talent/enrollment/front/getExistenRoll`,
    method: "get",
    params,
  });
}
// 提交报名信息
export function submitApply(data) {
  return request({
    url: `talent/enrollment/front/submitEnrollment`,
    method: "post",
    data,
  });
}
// 查询我的报名信息/进度/成绩
export function getMyScore(params) {
  return request({
    url: `talent/enrollment/front/getEnrollmentByUser`,
    method: "get",
    params,
  });
}
// 查询我报名的项目
export function getMyProject() {
  return request({
    url: `talent/enrollment/front/getEnrollmentProject`,
    method: "get",
  });
}
// 查询全局配置
export function getConfig() {
  return request({
    url: `talent/sys-web-config/front`,
    method: "get",
  });
}
