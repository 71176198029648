import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../layout";
import { menuRoutes } from "./menuRoutes";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "layout",
    component: Layout,
    children: [...menuRoutes],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
