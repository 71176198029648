<!-- 新闻页 -->
<template>
  <div class="frame">
    <div class="content">
      <div class="types">
        <div class="type-title">通知公告</div>
        <div
          :class="{ type: true, active: item.code == queryParams.newsCode }"
          v-for="item in types"
          :key="item.id"
          @click="changeType(item)"
        >
          <p>{{ item.name }}</p>
        </div>
      </div>
      <div class="list">
        <div
          class="item"
          v-for="item in list"
          :key="item.id"
          @click="navTo(item)"
        >
          <div class="post" v-show="item.newsImageUrl">
            <img :src="URL + item.newsImageUrl" :alt="item.title" />
          </div>
          <div class="right">
            <div class="title">{{ item.title }}</div>
            <div class="time">
              {{ item.publishTime || item.createTime | handleTime }}
            </div>
          </div>
        </div>
        <el-pagination
          class="pagination"
          @current-change="currentChange"
          :current-page.sync="queryParams.pageIndex"
          :page-size="queryParams.pageSize"
          layout="total, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { URL } from "../util/config";
export default {
  data() {
    return {
      URL,
      types: [
        { id: 1, name: "通知公告", code: "TONGZHIGONGGAO" },
        // { id: 2, name: "机构概况", code: "JIGOUGAIKUANG" },
        // { id: 3, name: "部门文件", code: "BUMENWENJIAN" },
        // { id: 4, name: "党外知识分子", code: "DANGWAIZHISHIFENZI" },
        // { id: 5, name: "港澳台侨务工作", code: "GANGAOTAIQIAOWUGONGZUO" },
        // { id: 6, name: "非公有制经济", code: "FEIGONGYOUZHIJINGJI" },
        // { id: 7, name: "基层统战", code: "JICENGTONGZHAN" },
        // { id: 8, name: "民族宗教", code: "MINZUZONGJIAO" },
        // { id: 9, name: "多党合作", code: "DUODANGHEZUO" },
        // { id: 10, name: "图片中心", code: "TUPIANZHONGXIN" },
      ],
      activeType: "1",
      queryParams: {
        pageIndex: 1,
        pageSize: 10,
        newsCode: "",
      },
      list: [],
      total: 10,
    };
  },
  created() {
    if (this.$route.query.code) {
      this.queryParams.newsCode = this.$route.query.code;
    } else {
      this.queryParams.newsCode = this.types[0].code;
    }
    this.getList();
  },
  mounted() {},
  methods: {
    changeType(item) {
      this.queryParams.newsCode = item.code;
      this.queryParams.pageIndex = 1;
      this.getList();
    },
    async getList() {
      let res = await this.$api.getNewsList(this.queryParams);
      this.list = res.data.list;
      this.total = res.data.total;
    },
    currentChange(val) {
      this.queryParams.pageIndex = val;
    },
    navTo(item) {
      this.$router.push("/newDetail?id=" + item.id);
    },
  },
  beforeRouteLeave(to, from, next) {
    from.meta.keepAlive = to.path == "/newDetail";
    next();
  },
};
</script>

<style lang="scss" scoped>
.frame {
  min-height: calc(100vh - 316px);
  margin: 20px 0;
}
.content {
  width: 1200px;
  margin: 0 auto;
  display: flex;

  .types {
    width: 300px;

    .type-title {
      padding: 20px 0;
      background-color: $theme;
      color: #fff;
      font-size: 20px;
      font-weight: bold;
      text-align: center;
    }
    .type {
      padding: 10px 25px;
      box-sizing: border-box;
      background-color: #fff;
      font-size: 18px;
      border-bottom: 1px solid #eee;

      &:hover {
        cursor: pointer;
        color: $theme;
        font-weight: bold;
        border-color: $theme;
      }

      p {
        position: relative;

        &::after {
          font-size: 12px;
          content: "\25BA";
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          display: none;
        }
      }
    }
    .active {
      cursor: pointer;
      color: $theme;
      font-weight: bold;
      border-color: $theme;

      p::after {
        display: block;
      }
    }
  }
  .list {
    flex-grow: 1;
    margin-left: 20px;
    background-color: #fff;

    .item {
      display: flex;
      align-items: flex-start;
      padding: 10px 20px;
      box-sizing: border-box;
      transition: 0.5s;

      &:hover {
        cursor: pointer;
        background-color: rgba($color: #000000, $alpha: 0.1);

        .post img {
          transform: scale(1.1);
        }
        .title {
          color: $theme;
          transform: translateX(-5px);
        }
      }
      .post {
        width: 200px;
        height: 150px;
        border-radius: 5px;
        margin-right: 10px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: 0.5s;
        }
      }
      .right {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 0;
        flex-grow: 1;
      }

      .title {
        transition: 0.5s;
      }
      .time {
        color: #999;
        margin-left: 10px;
        font-size: 14px;
        flex-shrink: 0;
      }
    }
  }
  .pagination {
    text-align: center;
    margin: 10px 0;
  }
}
@media (max-width: 750px) {
  .frame {
    margin-top: 50px;
  }
  .content {
    width: 100%;

    .types {
      display: none;
    }
    .list {
      width: 100%;
      margin-left: 0;
      padding: 10px;
      box-sizing: border-box;

      .item {
        padding: 0;
        margin-bottom: 10px;
        flex-direction: column;
        border-bottom: 1px solid #aaa;

        .post {
          width: 100%;
          height: auto;
          margin-right: 0;

          img {
            width: 100%;
            height: auto;
          }
        }
        .right {
          width: 100%;
        }
      }
    }
  }
}
</style>
