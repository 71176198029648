import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import "element-ui/lib/theme-chalk/index.css";
import ElementUI from "element-ui";
Vue.use(ElementUI);

import { Toast, Form, Dialog, Field, Popup, Picker, Calendar } from "vant";
Vue.use(Toast);
Vue.use(Form);
Vue.use(Dialog);
Vue.use(Field);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Calendar);

Vue.config.productionTip = false;

import * as api from "./util/api";
Vue.prototype.$api = api;
Vue.prototype.$isMobile = () => {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  return !!flag;
};

Vue.filter("handleTime", (time) => {
  return time && time.split(" ")[0];
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
