<!-- 成绩进度模块 -->
<template>
  <div class="frame">
    <div class="content">
      <div class="top">
        <div class="bread">
          <span>您当前所在：</span>
          <BreadCrumb :breadcrumbs="routeArr" />
        </div>
        <UserInfo />
      </div>
      <div class="box">
        <div class="step">基本信息</div>
        <el-descriptions
          :column="2"
          size="medium"
          border
          labelClassName="desc-label"
          title="个人信息"
        >
          <el-descriptions-item label="报名项目">{{
            detail.projectName
          }}</el-descriptions-item>
          <!-- <el-descriptions-item label="准考证">{{
            detail.admissionNumber || "XXXX"
          }}</el-descriptions-item> -->
          <el-descriptions-item label="姓名">{{
            detail.name
          }}</el-descriptions-item>
          <el-descriptions-item label="性别">{{
            detail.sex
          }}</el-descriptions-item>
          <el-descriptions-item label="出生年月">{{
            detail.birth && detail.birth.split(" ")[0]
          }}</el-descriptions-item>
          <el-descriptions-item label="民族">{{
            detail.ethnic
          }}</el-descriptions-item>
          <el-descriptions-item label="现居住地">{{
            detail.currentResidence
          }}</el-descriptions-item>
          <el-descriptions-item label="学历">{{
            detail.education
          }}</el-descriptions-item>
          <!-- <el-descriptions-item label="毕业院校">{{
            detail.graduateSchool
          }}</el-descriptions-item>
          <el-descriptions-item label="所学专业">{{
            detail.specialization
          }}</el-descriptions-item>
          <el-descriptions-item label="毕业时间">{{
            detail.graduationTime.split(" ")[0]
          }}</el-descriptions-item> -->
          <el-descriptions-item label="婚姻状况">{{
            detail.maritalStatus
          }}</el-descriptions-item>
          <el-descriptions-item label="政治面貌">{{
            detail.politicalProfile
          }}</el-descriptions-item>
          <el-descriptions-item label="工作年限">{{
            detail.workingSeniority
          }}</el-descriptions-item>
          <el-descriptions-item label="职称">{{
            detail.position
          }}</el-descriptions-item>
          <el-descriptions-item label="身份证号">{{
            detail.idNo.replace(/(?<=\d{4})\d{11}(?=\d{2})/, "***********")
          }}</el-descriptions-item>
          <el-descriptions-item label="联系电话">{{
            detail.linkPhone.replace(/(\d{3})\d*(\d{4})/, "$1****$2")
          }}</el-descriptions-item>
          <el-descriptions-item label="邮箱" :span="2">{{
            detail.email
          }}</el-descriptions-item>
          <el-descriptions-item label="个人简历" :span="2">{{
            detail.personalResume
          }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions
          title="求职意向"
          :column="2"
          size="medium"
          border
          labelClassName="desc-label"
        >
          <el-descriptions-item label="现月薪(税前)">{{
            detail.currentMonthlySalary
          }}</el-descriptions-item>
          <el-descriptions-item label="期望月薪(税前)">{{
            detail.expectedSalary
          }}</el-descriptions-item>
          <el-descriptions-item label="到岗时间">{{
            detail.onDutyTime
          }}</el-descriptions-item>
        </el-descriptions>
        <div class="sub-title">教育经历</div>
        <el-table :data="detail.educationalExperienceList" stripe border>
          <el-table-column label="学校名称" prop="schoolName"></el-table-column>
          <el-table-column
            label="所学专业"
            prop="majorStudied"
          ></el-table-column>
          <el-table-column label="学历" prop="degree"></el-table-column>
          <el-table-column label="学位" prop="academicDegree"></el-table-column>
          <el-table-column
            label="在校时间"
            prop="educationBetweenTime"
          ></el-table-column>
        </el-table>
        <div class="sub-title">工作经历</div>
        <el-table :data="detail.workExperienceList" stripe border>
          <el-table-column
            label="公司名称"
            prop="experienceCompanyName"
          ></el-table-column>
          <el-table-column label="岗位" prop="station"></el-table-column>
          <el-table-column
            label="工作日期"
            prop="workingBetweenTime"
          ></el-table-column>
          <el-table-column label="主要职责" prop="jobResponsibilities">
            <template slot-scope="{ row }">
              <p class="special">{{ row.jobResponsibilities }}</p>
            </template>
          </el-table-column>
        </el-table>
        <div class="step">招聘进度</div>
        <el-timeline>
          <el-timeline-item
            size="large"
            placement="top"
            :timestamp="detail.createTime | handleTime"
            type="success"
            icon="el-icon-check"
          >
            <el-card>
              <div class="h4 success">报名成功</div>
            </el-card>
          </el-timeline-item>
          <el-timeline-item
            size="large"
            placement="top"
            :timestamp="detail.initialSiftingTime | handleTime"
            :type="handleTimelineIcon(-1, 1)[1]"
            :icon="handleTimelineIcon(-1, 1)[0]"
            :hide-timestamp="detail.enrollmentStatus == -1"
          >
            <el-card>
              <div class="h4" v-if="!detail.initialSiftingTime">等待初审</div>
              <div class="h4 danger" v-else-if="detail.enrollmentStatus == 1">
                未通过，谢谢您的参与。请持续关注更多招聘信息。
              </div>
              <div class="h4 success" v-else>
                通过初审
                <span class="tip">{{ projectInfo.firstreviewPassRemark }}</span>
              </div>
            </el-card>
          </el-timeline-item>
          <el-timeline-item
            size="large"
            placement="top"
            :timestamp="detail.interviewSiftingTime | handleTime"
            :type="handleTimelineIcon(0, 3)[1]"
            :icon="handleTimelineIcon(0, 3)[0]"
            :hide-timestamp="detail.enrollmentStatus <= 1"
          >
            <el-card>
              <div class="h4" v-if="!detail.interviewSiftingTime">等待笔试</div>
              <div class="h4 danger" v-else-if="detail.enrollmentStatus == 3">
                <span>成绩：{{ detail.firstexamScore }}分</span>
                笔试未通过，感谢您参加本次招聘。
              </div>
              <div class="h4 success" v-else>
                通过笔试<span>成绩：{{ detail.firstexamScore }}分</span>
                <span class="tip">{{ projectInfo.writtentestPassRemark }}</span>
              </div>
            </el-card>
          </el-timeline-item>
          <el-timeline-item
            size="large"
            placement="top"
            :type="handleTimelineIcon(4, 7)[1]"
            :icon="handleTimelineIcon(4, 7)[0]"
            :timestamp="detail.employTime | handleTime"
            :hide-timestamp="detail.enrollmentStatus <= 5"
          >
            <el-card>
              <div class="h4" v-if="!detail.employTime">等待面试</div>
              <div class="h4 danger" v-else-if="detail.enrollmentStatus == 7">
                <span>
                  面试成绩：{{ detail.secondScore }}分, 综合成绩：{{
                    detail.finalScore
                  }}分
                </span>
                面试未通过：综合成绩未进入前2，很遗憾未能录取，感谢你对本次招聘工作的支持，谢谢你的参与，请持续关注更多招聘信息。
              </div>
              <div class="h4 success" v-else>
                通过面试
                <span>
                  面试成绩：{{ detail.secondScore }}分，综合成绩：{{
                    detail.finalScore
                  }}分
                </span>
                <span class="tip">{{ projectInfo.interviewPassRemark }}</span>
              </div>
            </el-card>
          </el-timeline-item>
        </el-timeline>
        <div class="result" v-if="detail.enrollmentStatus == 6">
          <div class="step">招聘结果</div>
          <el-descriptions direction="vertical" :column="5" border>
            <el-descriptions-item label="综合成绩">{{
              detail.finalScore
            }}</el-descriptions-item>
            <el-descriptions-item label="体检结果">合格</el-descriptions-item>
            <el-descriptions-item label="考察结果">合格</el-descriptions-item>
            <el-descriptions-item label="公示情况">
              无异议
            </el-descriptions-item>
            <el-descriptions-item label="上岗时间"
              >10月30日左右</el-descriptions-item
            >
          </el-descriptions>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "../components/breadCrumb.vue";
import UserInfo from "../components/userInfo.vue";
export default {
  data() {
    return {
      routeArr: [
        { name: "首页", path: "/" },
        { name: "信息查询", path: "/query" },
        { name: "招聘进度" },
      ],
      detail: {
        graduationTime: "",
        idNo: "",
        linkPhone: "",
      },
      projectInfo: {},
    };
  },
  components: { BreadCrumb, UserInfo },
  created() {
    // 前置判断
    if (!this.$route.query.id || !sessionStorage.getItem("token")) {
      this.$router.replace("/query");
      return;
    }
    this.getList();
  },
  mounted() {},
  methods: {
    // 时间戳图标、颜色处理
    handleTimelineIcon(moreFlag, failFlag) {
      const { enrollmentStatus } = this.detail;
      if (enrollmentStatus == moreFlag) {
        return ["el-icon-more", "primary"];
      }
      if (enrollmentStatus > failFlag || enrollmentStatus == failFlag - 1) {
        return ["el-icon-check", "success"];
      }
      if (enrollmentStatus == failFlag) {
        return ["el-icon-close", "danger"];
      }
      return ["", ""];
    },
    // 获取成绩进度列表
    async getList() {
      let res = await this.$api.getMyScore({ projectId: this.$route.query.id });
      if (res.code != 0) {
        this.$router.back();
        return;
      }
      this.detail = res.data.enrollmentVoList[0];
      this.detail.finalScore =
        Number((this.detail.firstexamScore * 0.4).toFixed(2)) +
        Number((this.detail.secondScore * 0.6).toFixed(2));
      this.projectInfo = res.data;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.red) {
  color: #f56c6c;

  &:hover {
    color: #f78989;
  }
}
.frame {
  min-height: calc(100vh - 316px);
  margin-bottom: 20px;

  .content {
    width: 1200px;
    margin: 0 auto;

    .top {
      margin: 10px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .bread {
        display: flex;
        align-items: center;
        font-size: 14px;
      }
    }
    .userinfo {
      font-size: 14px;

      span {
        margin-right: 5px;
      }
    }
    .step {
      margin: 10px 0;
      text-align: center;
      font-size: 26px;
      font-weight: bold;
    }
    :deep(.desc-label) {
      width: 15%;
    }
    :deep(.el-table th.el-table__cell) {
      background: #f9fbfd;
      color: #385e7a;
    }
    .box {
      background-color: #fff;
      padding: 20px;

      .h4 {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 10px;
      }
      .success {
        color: #67c23a;

        span {
          font-size: 15px;
          margin-left: 20px;
        }
        .tip {
          font-size: 14px;
          margin-left: 20px;
          color: #666;
          font-weight: normal;
        }
      }
      .danger {
        color: #f56c6c;

        span {
          font-size: 15px;
          margin-right: 20px;
        }
      }
    }
  }
}
.sub-title {
  margin: 10px 0 20px;
  font-weight: 700;
}
.special {
  word-break: break-all;
  white-space: pre-wrap;
}
.el-descriptions + .el-descriptions {
  margin-top: 10px;
}
.el-table {
  margin-top: 10px;
}
@media (max-width: 750px) {
  .frame {
    min-height: calc(100vh - 90px);
    margin: 50px 0 10px;

    .content {
      width: 100%;
      padding-top: 1px;

      .top {
        padding: 0 10px;
        box-sizing: border-box;
        flex-wrap: wrap;
      }
      :deep(.desc-label) {
        min-width: 60px;
      }
      .box {
        padding: 10px;
      }
    }
  }
}
</style>
