<!-- 首页 -->
<template>
  <div class="frame">
    <div class="container">
      <div class="box">
        <div class="block brand">
          <div id="swiper">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="item in indexNewsList['TONGZHIGONGGAO']"
                :key="item.id"
                @click="navToDetail(item)"
              >
                <img
                  :src="URL + item.newsImageUrl"
                  :alt="item.title"
                  class="post"
                />
                <div class="title">{{ item.title }}</div>
              </div>
            </div>
            <div class="swiper-pagination"></div>
          </div>
        </div>
        <div class="block new-center">
          <div class="title-box">
            <div class="title">通知公告</div>
            <router-link to="/news?code=TONGZHIGONGGAO" class="more"
              >更多&#10148;</router-link
            >
          </div>
          <div class="content">
            <div class="list">
              <div
                class="item dot"
                v-for="item in indexNewsList['TONGZHIGONGGAO']"
                :key="item.id"
                @click="navToDetail(item)"
              >
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
        <div class="block menus">
          <div class="content">
            <div class="menu" @click="navTo('/apply')">
              <img src="@/assets/apply.png" class="icon" />
              <div class="label">报名入口</div>
            </div>
            <div class="menu" @click="navTo('/query')">
              <img src="@/assets/search.png" class="icon" />
              <div class="label">查询进度</div>
            </div>
          </div>
        </div>
      </div>
      <div class="box" v-if="false">
        <div class="block public">
          <div class="title-box">
            <div class="title">机构概况</div>
            <router-link to="/news?code=JIGOUGAIKUANG" class="more"
              >更多&#10148;</router-link
            >
          </div>
          <div class="content">
            <div class="news">
              <router-link
                class="new"
                :to="'/newDetail?id=' + item.id"
                v-for="item in indexNewsList['JIGOUGAIKUANG']"
                :key="item.id"
              >
                <div class="title dot">{{ item.title }}</div>
                <div class="time">
                  {{ item.publishTime || item.createTime | handleTime }}
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <div class="block public">
          <div class="title-box">
            <div class="title">部门文件</div>
            <router-link to="/news?code=BUMENWENJIAN" class="more"
              >更多&#10148;</router-link
            >
          </div>
          <div class="content">
            <div class="news">
              <router-link
                class="new"
                :to="'/newDetail?id=' + item.id"
                v-for="item in indexNewsList['BUMENWENJIAN']"
                :key="item.id"
              >
                <div class="title dot">{{ item.title }}</div>
                <div class="time">
                  {{ item.publishTime || item.createTime | handleTime }}
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <div class="block notice">
          <div class="title-box">
            <div class="title">通知公告</div>
            <router-link to="/news?code=TONGZHIGONGGAO" class="more"
              >更多&#10148;</router-link
            >
          </div>
          <div class="content">
            <div class="list">
              <router-link
                :to="'/newDetail?id=' + item.id"
                class="item"
                v-for="item in indexNewsList['TONGZHIGONGGAO']"
                :key="item.id"
              >
                <div class="title">{{ item.title }}</div>
                <div class="time">
                  {{ item.publishTime || item.createTime | handleTime }}
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <img class="slogan" src="@/assets/kv.jpeg" alt="slogan" v-if="false" />
      <div class="box" v-if="false">
        <div class="block public other">
          <div class="title-box">
            <div class="title">党外知识分子</div>
            <router-link to="/news?code=DANGWAIZHISHIFENZI" class="more"
              >更多&#10148;</router-link
            >
          </div>
          <div class="content">
            <div class="news">
              <router-link
                class="new"
                :to="'/newDetail?id=' + item.id"
                v-for="item in indexNewsList['DANGWAIZHISHIFENZI']"
                :key="item.id"
              >
                <div class="title dot">{{ item.title }}</div>
                <div class="time">
                  {{ item.publishTime || item.createTime | handleTime }}
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <div class="block public other">
          <div class="title-box">
            <div class="title">港澳台侨务工作</div>
            <router-link to="/news?code=GANGAOTAIQIAOWUGONGZUO" class="more"
              >更多&#10148;</router-link
            >
          </div>
          <div class="content">
            <div class="news">
              <router-link
                class="new"
                :to="'/newDetail?id=' + item.id"
                v-for="item in indexNewsList['GANGAOTAIQIAOWUGONGZUO']"
                :key="item.id"
              >
                <div class="title dot">{{ item.title }}</div>
                <div class="time">
                  {{ item.publishTime || item.createTime | handleTime }}
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <div class="block public other">
          <div class="title-box">
            <div class="title">非公有制经济</div>
            <router-link to="/news?code=FEIGONGYOUZHIJINGJI" class="more"
              >更多&#10148;</router-link
            >
          </div>
          <div class="content">
            <div class="news">
              <router-link
                class="new"
                :to="'/newDetail?id=' + item.id"
                v-for="item in indexNewsList['FEIGONGYOUZHIJINGJI']"
                :key="item.id"
              >
                <div class="title dot">{{ item.title }}</div>
                <div class="time">
                  {{ item.publishTime || item.createTime | handleTime }}
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="box" v-if="false">
        <div class="block public other">
          <div class="title-box">
            <div class="title">基层统战</div>
            <router-link to="/news?code=JICENGTONGZHAN" class="more"
              >更多&#10148;</router-link
            >
          </div>
          <div class="content">
            <div class="news">
              <router-link
                class="new"
                :to="'/newDetail?id=' + item.id"
                v-for="item in indexNewsList['JICENGTONGZHAN']"
                :key="item.id"
              >
                <div class="title dot">{{ item.title }}</div>
                <div class="time">
                  {{ item.publishTime || item.createTime | handleTime }}
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <div class="block public other">
          <div class="title-box">
            <div class="title">民族宗教</div>
            <router-link to="/news?code=MINZUZONGJIAO" class="more"
              >更多&#10148;</router-link
            >
          </div>
          <div class="content">
            <div class="news">
              <router-link
                class="new"
                :to="'/newDetail?id=' + item.id"
                v-for="item in indexNewsList['MINZUZONGJIAO']"
                :key="item.id"
              >
                <div class="title dot">{{ item.title }}</div>
                <div class="time">
                  {{ item.publishTime || item.createTime | handleTime }}
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <div class="block public other">
          <div class="title-box">
            <div class="title">多党合作</div>
            <router-link to="/news?code=DUODANGHEZUO" class="more"
              >更多&#10148;</router-link
            >
          </div>
          <div class="content">
            <div class="news">
              <router-link
                class="new"
                :to="'/newDetail?id=' + item.id"
                v-for="item in indexNewsList['DUODANGHEZUO']"
                :key="item.id"
              >
                <div class="title dot">{{ item.title }}</div>
                <div class="time">
                  {{ item.publishTime || item.createTime | handleTime }}
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="box" v-if="false">
        <div class="block public full">
          <div class="title-box">
            <div class="title">图片中心</div>
          </div>
          <div class="content">
            <div id="slider">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-for="item in indexNewsList['TUPIANZHONGXIN']"
                  :key="item.id"
                  @click="navToDetail(item)"
                >
                  <img
                    :src="URL + item.newsImageUrl"
                    :alt="item.title"
                    class="post"
                  />
                  <div class="title">{{ item.title }}</div>
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { URL } from "../util/config";
export default {
  data() {
    return {
      URL,
      swiperList: [],
      types: {
        JIGOUGAIKUANG: 8, // 机构概况
        BUMENWENJIAN: 8, // 部门文件
        TONGZHIGONGGAO: 10, // 通知公告
        DANGWAIZHISHIFENZI: 5, // 党外知识分子
        GANGAOTAIQIAOWUGONGZUO: 5, // 港澳台侨务工作
        FEIGONGYOUZHIJINGJI: 5, // 非公有制经济
        JICENGTONGZHAN: 5, // 基层统战
        MINZUZONGJIAO: 5, // 民族宗教
        DUODANGHEZUO: 5, // 多党合作
        TUPIANZHONGXIN: 5, // 图片中心
      },
      indexNewsList: {
        JIGOUGAIKUANG: [], // 机构概况
        BUMENWENJIAN: [], // 部门文件
        TONGZHIGONGGAO: [], // 通知公告
        DANGWAIZHISHIFENZI: [], // 党外知识分子
        GANGAOTAIQIAOWUGONGZUO: [], // 港澳台侨务工作
        FEIGONGYOUZHIJINGJI: [], // 非公有制经济
        JICENGTONGZHAN: [], // 基层统战
        MINZUZONGJIAO: [], // 民族宗教
        DUODANGHEZUO: [], // 多党合作
        TUPIANZHONGXIN: [], // 图片中心
      }, // 剩余所有新闻列表
      newList: [], // 新闻中心
    };
  },
  created() {},
  mounted() {
    this.getSwiperList();
    this.getAllNews();
  },
  methods: {
    async getSwiperList() {
      const params = {
        pageIndex: 1,
        pageSize: 7,
      };
      let res = await this.$api.getNewsList(params);
      this.swiperList = res.data.list.filter((ele) => {
        return !!ele.newsImageUrl;
      });
      setTimeout(() => {
        this.initSwiper();
      }, 10);
    },
    async getAllNews() {
      let params = [];
      for (const key in this.types) {
        params.push({
          newsCode: key,
          newsTotal: this.types[key],
        });
      }
      let res = await this.$api.getHomeNews(params);
      for (const key in res.data) {
        const ele = res.data[key];
        this.indexNewsList[key] = ele;
      }
      if (this.indexNewsList["TUPIANZHONGXIN"].length > 4) {
        this.initSlider();
      }
    },
    initSwiper() {
      new window.Swiper("#swiper", {
        loop: true,
        autoplay: true,
        pagination: {
          el: ".swiper-pagination",
          bulletClass: "banner-bullet",
          bulletActiveClass: "banner-bullet-active",
        },
      });
    },
    initSlider() {
      if (this.$isMobile()) {
        // 手机端
        new window.Swiper("#slider", {
          loop: true,
          autoplay: true,
          pagination: {
            el: ".swiper-pagination",
            bulletClass: "banner-bullet",
            bulletActiveClass: "banner-bullet-active",
          },
        });
      } else {
        new window.Swiper("#slider", {
          loop: true,
          loopedSlides: 6,
          autoplay: true,
          slidesPerView: 4,
          spaceBetween: 10,
          pagination: {
            el: ".swiper-pagination",
            bulletClass: "banner-bullet",
            bulletActiveClass: "banner-bullet-active",
          },
        });
      }
    },
    // 前往新闻详情
    navToDetail(item) {
      this.$router.push("/newDetail?id=" + item.id);
    },
    navTo(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss" scoped>
.dot {
  position: relative;

  &::after {
    content: "";
    display: block;
    width: 3px;
    height: 3px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: $theme;
  }
}
.frame {
  width: 100%;
  min-height: calc(100vh - 277px);
  padding: 20px 0;
  box-sizing: border-box;

  .container {
    width: 1200px;
    margin: 0 auto;

    .box {
      display: flex;

      & + .box {
        margin-top: 10px;
      }

      .block {
        border: #e8e8e8 solid 1px;
        background-color: #fff;
        padding: 10px 15px;
        box-sizing: border-box;

        .title-box {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .title {
            font-size: 20px;
          }
          .more {
            font-size: 14px;

            &:hover {
              cursor: pointer;
              text-decoration: underline;
              color: $theme;
            }
          }
        }
      }
      .brand {
        width: 460px;

        #swiper {
          position: relative;
          overflow: hidden;

          ::v-deep {
            .banner-bullet {
              width: 20px;
              height: 3px;
              display: inline-block;
              background-color: #f5f5f5;
              margin: 0 5px;
              border-radius: 10px;
            }
            .banner-bullet-active {
              background-color: $theme;
            }
          }
          .swiper-slide {
            width: 100%;

            &:hover {
              cursor: pointer;

              .title {
                color: $theme;
              }
            }

            .post {
              width: 100%;
              height: 306px;
              object-fit: cover;
              display: block;
            }
            .title {
              font-size: 15px;
              padding: 10px 10px 20px;
              box-sizing: border-box;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
      }
      .new-center {
        width: 470px;
        border-left: 0;

        .title-box {
          margin-bottom: 20px;
          line-height: 41px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .title {
            width: 108px;
            padding-left: 18px;
            background: url("@/assets/title_bg.gif") no-repeat 0 center /
              contain;
            color: #fff;
          }
        }
        .content {
          .list {
            .item {
              line-height: 40px;
              padding-left: 10px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              border-bottom: 1px dotted #d9d9d9;
              font-size: 14px;
              cursor: pointer;
              transition: 0.5s;

              &:first-child {
                font-size: 16px;
                color: $theme;
              }
              &:last-child {
                border-bottom: 0;
              }
              &:hover {
                transform: translateX(-5px);
                color: $theme;
              }
            }
          }
        }
      }
      .notice {
        width: 257px;
        margin-left: 10px;
        padding: 0 15px 10px;
        border-top: 2px solid #e13f38;

        .title-box {
          border-bottom: #e8e8e8 solid 1px;
          padding: 10px 0;
          margin-bottom: 20px;

          .title {
            color: $theme;
          }
        }
        .content {
          max-height: 300px;
          overflow: auto;

          .list {
            .item {
              font-size: 14px;
              padding-left: 10px;
              display: block;
              border-bottom: 1px dashed #d9d9d9;
              padding-bottom: 10px;
              margin-bottom: 10px;
              position: relative;

              &:hover {
                .title {
                  color: $theme;
                }
              }

              &::after {
                content: "";
                width: 3px;
                height: 3px;
                background-color: $theme;
                position: absolute;
                left: 0;
                top: 8px;
              }

              .time {
                color: #999;
                font-size: 13px;
                margin-top: 5px;
              }
            }
          }
        }
      }
      .public {
        width: 460px;
        box-sizing: border-box;
        padding: 0 15px 10px;
        border-top: 2px solid $theme;

        & + .public {
          margin-left: 10px;
        }

        .title-box {
          margin-bottom: 5px;
          padding: 10px 0;
          border-bottom: 1px solid #e8e8e8;

          .title {
            color: $theme;
            padding-left: 15px;
            position: relative;

            &::after {
              content: "";
              width: 4px;
              height: 80%;
              border-radius: 10px;
              background-color: $theme;
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
        .content {
          .news {
            .new {
              display: flex;
              justify-content: space-between;
              align-items: center;
              border-bottom: 1px dotted #d9d9d9;
              line-height: 40px;

              &:hover {
                .title {
                  transform: translateX(-5px);
                  color: $theme;
                }
              }

              &:last-child {
                border-bottom: 0;
              }

              .title {
                font-size: 14px;
                padding-left: 10px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                transition: 0.5s;
              }
              .time {
                color: #999;
                font-size: 13px;
                margin-left: 10px;
                flex-shrink: 0;
              }
            }
          }

          #slider {
            position: relative;
            overflow: hidden;
            padding-bottom: 15px;

            ::v-deep {
              .banner-bullet {
                width: 20px;
                height: 3px;
                display: inline-block;
                background-color: #f5f5f5;
                margin: 0 5px;
                border-radius: 10px;
              }
              .banner-bullet-active {
                background-color: $theme;
              }
              .swiper-horizontal > .swiper-pagination-bullets,
              .swiper-pagination-bullets.swiper-pagination-horizontal {
                bottom: 0;
              }
            }

            .swiper-slide {
              font-size: 14px;
              max-width: 30%;

              &:hover {
                cursor: pointer;

                .title {
                  color: $theme;
                }
              }

              .post {
                width: 100%;
                height: 213px;
                overflow: cover;
              }
              .title {
                height: 40px;
                margin-top: 10px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
              }
            }
          }
        }
      }
      .menus {
        width: auto;
        margin-left: 10px;
        padding: 0;
        flex-grow: 1;

        .content {
          height: 100%;
          display: flex;
          flex-direction: column;

          .menu {
            height: 50%;
            padding: 10px 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: $theme;
            font-size: 20px;
            cursor: pointer;
            background-image: linear-gradient(to bottom, #fff, #f4f6fc);

            & + .menu {
              border-top: 1px solid #e8e8e8;
            }

            &:active {
              transform: scale(0.9);
            }

            .icon {
              width: auto;
              height: 50px;
              margin-bottom: 10px;
            }
          }
        }
      }
      .other {
        width: 393px;
      }
      .full {
        width: 100%;
      }
    }

    .slogan {
      width: 100%;
      height: auto;
      display: block;
      margin: 10px 0;
    }
  }
}

@media (max-width: 750px) {
  .frame {
    padding: 60px 0 10px;
    min-height: calc(100vh - 30px);
  }
  .frame .container {
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;

    .box {
      flex-direction: column;

      .block {
        width: 100%;
      }
      .menus,
      .notice,
      .public + .public {
        margin-left: 0;
        margin-top: 10px;
      }
      .public .content #slider {
        .swiper-slide {
          max-width: 100%;
        }
      }
      .brand {
        padding: 0;

        #swiper .swiper-slide .post {
          height: 228px;
        }
      }
      .new-center {
        margin-top: 10px;
      }
      .menus {
        .content {
          flex-direction: row;

          .menu {
            width: 50%;
            height: 100%;
            padding: 20px 0;

            & + .menu {
              border-top: 0;
              border-left: 1px solid #e8e8e8;
            }
            .icon {
              height: 40px;
            }
            .label {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}
</style>
