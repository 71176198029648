<!-- 成绩查询页面 -->
<template>
  <div class="frame">
    <div class="box" v-if="!showProject">
      <div class="in">
        <div class="title">信息查询</div>
        <el-form
          ref="form"
          :model="queryParams"
          :rules="queryRules"
          label-width="80px"
        >
          <el-form-item label="身份证" prop="idNo" required>
            <el-input v-model="queryParams.idNo"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="linkPhone" required>
            <el-input v-model="queryParams.linkPhone" type="number"></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="smsCode" required>
            <div class="same">
              <el-input
                v-model="queryParams.smsCode"
                placeholder="请输入验证码"
              ></el-input>
              <el-button
                type="primary"
                :disabled="cd != maxCd"
                @click="sendCode"
                >{{ codeText }}</el-button
              >
            </div>
          </el-form-item>
          <div class="btn">
            <el-button type="primary" @click="startSearch">开始查询</el-button>
          </div>
        </el-form>
      </div>
    </div>
    <div class="project-box" v-else>
      <UserInfo />
      <el-table :data="myProjectList">
        <el-table-column
          label="报名项目"
          prop="projectName"
          align="center"
          min-width="110px"
        ></el-table-column>
        <el-table-column
          label="报名开始时间"
          prop="startTime"
          align="center"
          min-width="110px"
        >
          <template slot-scope="{ row }">
            <p>{{ row.startTime.split(" ")[0] }}</p>
            <p>{{ row.startTime.split(" ")[1] }}</p>
          </template>
        </el-table-column>
        <el-table-column
          label="报名截止时间"
          prop="endTime"
          align="center"
          min-width="110px"
        >
          <template slot-scope="{ row }">
            <p>{{ row.endTime.split(" ")[0] }}</p>
            <p>{{ row.endTime.split(" ")[1] }}</p>
          </template>
        </el-table-column>
        <el-table-column label="项目进度" align="center" min-width="110px">
          <template slot-scope="{ row }">
            <el-tag :type="handleStatus(row.projectStatus).type">{{
              handleStatus(row.projectStatus).label
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" min-width="110px">
          <template slot-scope="{ row }">
            <el-button type="primary" size="small" @click="searchScore(row)"
              >查看进度</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import UserInfo from "../components/userInfo.vue";
export default {
  data() {
    const idNoValid = (r, v, cb) => {
      const validReg =
        /^\d{6}((((((19|20)\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|(((19|20)\d{2})(0[13578]|1[02])31)|((19|20)\d{2})02(0[1-9]|1\d|2[0-8])|((((19|20)([13579][26]|[2468][048]|0[48]))|(2000))0229))\d{3})|((((\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|((\d{2})(0[13578]|1[02])31)|((\d{2})02(0[1-9]|1\d|2[0-8]))|(([13579][26]|[2468][048]|0[048])0229))\d{2}))(\d|X|x)$/;
      if (!v || !validReg.test(v)) {
        cb("请填写正确的身份证信息");
        return;
      }
      cb();
    };
    const phoneValid = (r, v, cb) => {
      const validReg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
      if (!v || !validReg.test(v)) {
        cb("请填写正确的手机号");
        return;
      }
      cb();
    };
    return {
      queryParams: {
        linkPhone: "",
        idNo: "",
        code: "",
      },
      queryRules: {
        linkPhone: {
          validator: phoneValid,
          trigger: "blur",
        },
        idNo: {
          validator: idNoValid,
          trigger: "blur",
        },
        smsCode: {
          required: true,
          message: "请填写验证码",
          trigger: "blur",
        },
      },
      cd: 0,
      maxCd: 60, // 倒计时总数
      code: "", // 验证码
      codeText: "发送验证码", // 提示信息
      timer: null,
      myProjectList: [],
      showProject: false, // 是否展示个人成绩进度
      status: [
        { type: "info", label: "报名未开始", value: 0 },
        { type: "", label: "报名中", value: 1 },
        { type: "warning", label: "报名截止", value: 2 },
        { type: "success", label: "笔试结果已出", value: 3 },
        { type: "success", label: "面试结果已出", value: 5 },
      ],
    };
  },
  components: { UserInfo },
  created() {
    this.cd = this.maxCd;
    if (sessionStorage.getItem("token")) {
      // 若存在用户信息，直接请求数据
      this.getList();
    }
  },
  mounted() {},
  methods: {
    handleStatus(status) {
      return this.status.find((ele) => ele.value == status) || {};
    },
    // 发送短信验证码
    async sendCode() {
      if (this.cd != this.maxCd) return;
      let res = await this.$api.sendCode(this.queryParams.linkPhone);
      if (res.code != 0) return;
      this.$toast.success("发送成功!");
      this.computedCd(); // 先执行一次
      this.timer = setInterval(this.computedCd, 1000);
    },
    // 倒计时计算函数
    computedCd() {
      if (this.cd <= 0) {
        this.codeText = "重新获取";
        clearInterval(this.timer);
        this.cd = this.maxCd;
        return;
      }
      this.cd--;
      this.codeText = this.cd + "s后重新获取";
    },
    // 查询我报名的项目
    startSearch() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let res = await this.$api.validCode(this.queryParams);
          if (res.code != 0) return;
          this.$toast.success("登录成功!");
          // 缓存用户数据
          sessionStorage.setItem("token", res.data.token);
          const idNo = res.data.idNo.replace(
            /(?<=\d{4})\d{11}(?=\d{2})/,
            "***********"
          );
          sessionStorage.setItem("userInfo", idNo);
          this.getList();
        }
      });
    },
    async getList() {
      this.showProject = true;
      let res = await this.$api.getMyProject();
      this.myProjectList = res.data;
    },
    // 查看成绩进度
    searchScore(row) {
      this.$router.push("/score?id=" + row.id);
    },
  },
  beforeDestroy() {
    this.timer && clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
:deep(.red) {
  color: #f56c6c;

  &:hover {
    color: #f78989;
  }
}
.frame {
  min-height: calc(100vh - 316px);
  margin: 20px 0;
  position: relative;
}
.box {
  width: 550px;
  padding: 20px 0;
  border-radius: 10px;
  box-shadow: 0 0 5px #ddd;
  position: absolute;
  left: 50%;
  top: 15%;
  transform: translateX(-50%);
  background-color: #fff;

  .in {
    width: 360px;
    margin: 0 auto;
  }

  .title {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    font-weight: bold;
  }
  .same {
    width: 100%;
    display: flex;
  }
  .btn {
    text-align: center;
  }
}
.project-box {
  width: 1200px;
  margin: 0 auto;

  .userinfo {
    margin-bottom: 10px;
    text-align: right;
    font-size: 14px;

    span {
      margin-right: 5px;
    }
  }
}

@media (max-width: 750px) {
  .frame {
    background-color: #fff;
    min-height: calc(100vh - 90px);
    margin: 50px 0 10px;

    .box {
      padding: 20px;
      position: static;
      transform: translate(0);
      box-shadow: none;
    }
  }
  .project-box {
    width: 100%;

    .userinfo {
      padding-top: 10px;

      span {
        margin-right: 10px;
      }
    }
    .el-table {
      padding: 0 10px;
      box-sizing: border-box;
    }
  }
}
</style>
