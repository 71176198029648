<!-- 底部 -->
<template>
  <footer class="footer">
    <div class="container">
      <div class="copy">
        <span>{{ globalData.copyRightMsg }}</span>
        <a href="https://beian.miit.gov.cn/" target="_blank" rel="nofollow">{{
          globalData.icpMsg
        }}</a>
        <span v-show="globalData.technicalSupportPhone"
          >服务热线:{{ globalData.technicalSupportPhone }}</span
        >
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    globalData: {
      type: Object,
      default: () => {},
    },
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.footer {
  background-color: $theme;
  padding: 10px 0;
  color: #fff;

  .container {
    width: 1200px;
    margin: 0 auto;
    text-align: center;
    color: #fff;

    .copy {
      font-size: 13px;

      a {
        margin: 0 10px;
      }
      a:hover {
        text-decoration: underline;
      }
    }
  }
}

@media (max-width: 750px) {
  .footer {
    background-color: transparent;
    padding-top: 0;

    .container {
      color: #777;
      width: 100%;
    }
  }
}
</style>
