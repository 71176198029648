<!-- 顶部 -->
<template>
  <header class="header">
    <div class="topbar">
      <div class="in">
        <span>{{ globalData.name }}</span>
        <!-- <ul>
          <li>
            <a href="http://demo.92wailian.com/k20" class="wx">
              某某市委统战部
              <div class="img-center">
                <img src="@/assets/wx_code.png" />
              </div>
            </a>
          </li>
        </ul> -->
        <div class="nowtime">{{ nowTime }}</div>
      </div>
    </div>
    <div class="banner">
      <div class="in">
        <router-link to="/">
          <img
            class="logo"
            src="@/assets/logo2.png"
            alt="成都市武侯区产业经济与社会事业发展人才促进协会"
          />
        </router-link>
        <div class="icons">
          <div class="open" v-show="!mShow" @click="mShow = true">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div class="close" v-show="mShow" @click="mShow = false">
            <img src="@/assets/close.png" alt="" />
          </div>
        </div>
        <div class="nav-box" v-show="mShow" @click="mShow = false">
          <div class="navs" @click.stop>
            <div
              :class="{ nav: true, active: item.path == $route.path }"
              v-for="item in navList"
              :key="item.path"
              @click="navTo(item.path)"
            >
              <div class="title">{{ item.name }}</div>
              <div class="subs">
                <div class="sub" v-for="sub in item.children" :key="sub.path">
                  {{ sub.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="nav-box">
      <div class="navs" @click.stop>
        <div
          :class="{ nav: true, active: item.path == $route.path }"
          v-for="item in navList"
          :key="item.path"
          @click="navTo(item.path)"
        >
          <div class="title">{{ item.name }}</div>
          <div class="subs">
            <div class="sub" v-for="sub in item.subs" :key="sub.path">
              {{ sub.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { menuRoutes } from "../../router/menuRoutes";
export default {
  data() {
    return {
      navList: [],
      mShow: false,
      isMobile: false,
      nowTime: "",
      weeks: [
        { label: "星期日", value: 0 },
        { label: "星期一", value: 1 },
        { label: "星期二", value: 2 },
        { label: "星期三", value: 3 },
        { label: "星期四", value: 4 },
        { label: "星期五", value: 5 },
        { label: "星期六", value: 6 },
      ],
    };
  },
  props: {
    globalData: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    // 筛选处理
    this.navList = menuRoutes.filter((ele) => {
      return !ele.meta.hide;
    });
    this.isMobile = this.$isMobile();
    this.initTime();
  },
  mounted() {},
  methods: {
    // 初始化时间
    initTime() {
      let nowDate = new Date();
      const year = nowDate.getFullYear();
      const month = nowDate.getMonth() + 1;
      const day = nowDate.getDate();
      const date = year + "年" + month + "月" + day + "日";
      const week = this.weeks.find(
        (ele) => ele.value == nowDate.getDay()
      ).label;
      this.nowTime = date + "\t" + week;
    },
    navTo(path) {
      if (path != this.$route.path) {
        this.$router.push(path);
        this.mShow = false;
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes rotateFadeIn {
  from {
    opacity: 0;
    transform: scale(0.6);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
.header {
  width: 100%;

  --main-width: 1200px;

  .topbar {
    line-height: 40px;
    background-color: $theme;
    color: #fff;
    font-size: 14px;

    .in {
      width: var(--main-width);
      margin: 0 auto;
      display: flex;
      justify-content: space-between;

      ul {
        display: flex;

        li {
          color: #ddd;

          .wx {
            color: #fff;
            position: relative;
            display: block;
            background: url(@/assets/icoTz01.png) no-repeat left center;
            padding-left: 25px;

            &:hover {
              .img-center {
                display: block;
              }
            }

            .img-center {
              position: absolute;
              left: 50%;
              top: 100%;
              padding: 10px;
              background-color: #fff;
              box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
              z-index: 10;
              width: 120px;
              height: 120px;
              line-height: 120px;
              border-radius: 10px;
              margin-left: -70px;
              animation: fadeIn 0.4s;
              display: none;

              img {
                max-width: 100%;
                max-height: 100%;
                vertical-align: middle;
                transition: all 0.5s;
              }
            }
          }
        }
      }
      .nowtime {
        font-size: 14px;
        white-space: pre-wrap;
      }
    }
  }
  .banner {
    height: 144px;
    background-image: url("@/assets/banner.jpeg");
    background-size: cover;
    background-position: center center;

    .in {
      width: var(--main-width);
      margin: 0 auto;

      .logo {
        width: auto;
        margin: 30px 0;
        height: 78px;
        line-height: 78px;
      }
      .icons {
        display: none;

        .open {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          animation: rotateFadeIn 0.1s linear;

          span {
            display: block;
            height: 2px;
            background-color: $theme;

            &:first-child {
              width: 24px;
            }
            &:nth-child(2) {
              width: 12px;
              margin: 6px 0;
            }
            &:last-child {
              width: 18px;
            }
          }
        }
        .close {
          animation: rotateFadeIn 0.1s linear;

          img {
            width: 24px;
            display: block;
          }
        }
      }
      .nav-box {
        display: none;
      }
    }
  }
  .nav-box {
    background-color: $theme;
    color: #fff;
    border-bottom: 3px solid $subTheme;

    .navs {
      width: var(--main-width);
      margin: 0 auto;
      display: flex;

      .nav {
        border-right: 1px solid $subTheme;
        font-size: 20px;
        line-height: 50px;
        padding: 0 35px;
        color: #fff;

        &:first-child {
          border-left: 1px solid $subTheme;
        }

        &:hover {
          background-color: $subTheme;
          cursor: pointer;
        }
      }
      .active {
        background-color: $subTheme;
      }
    }
  }
}
@media (max-width: 750px) {
  .topbar {
    display: none;
  }
  .header {
    --main-width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;

    .banner {
      height: 100%;
      border-bottom: 1px solid #e8e8e8;

      .in {
        padding: 0 15px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo {
          height: 30px;
          margin: 10px 0;
          display: block;
        }
        .icons,
        .nav-box {
          display: block;
        }
      }
    }
    .nav-box {
      width: 100vw;
      height: 100vh;
      position: absolute;
      left: 0;
      top: 100%;
      z-index: 999;
      background-color: rgba($color: #000000, $alpha: 0.5);
      border-bottom: 0;
      display: none;

      .navs {
        width: 100%;
        background-color: #fff;
        flex-direction: column;

        .nav {
          border: 0;
          padding: 0;

          &:first-child {
            border-left: 0;
          }
          &:hover {
            background-color: #fff;
          }
          .title {
            color: #333;
            font-size: 15px;
            line-height: 40px;
            padding: 0 15px;
          }
        }
      }
    }
  }
}
</style>
