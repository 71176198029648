import index from "../views/index";
import query from "../views/query";
import score from "../views/score";
import apply from "../views/apply";
import applyForm from "../views/applyForm";
import news from "../views/news";
import newDetail from "../views/newDetail";

export const menuRoutes = [
  {
    path: "/",
    name: "首页",
    component: index,
    meta: {},
  },
  {
    path: "/news",
    name: "通知公告",
    component: news,
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/apply",
    name: "报名入口",
    component: apply,
    meta: {},
  },
  {
    path: "/applyForm",
    name: "报名表单",
    component: applyForm,
    meta: {
      hide: true,
    },
  },
  {
    path: "/query",
    name: "信息查询",
    component: query,
    meta: {},
  },
  {
    path: "/score",
    name: "报名进度",
    component: score,
    meta: {
      hide: true,
    },
  },
  {
    path: "/newDetail",
    name: "新闻详情",
    component: newDetail,
    meta: {
      hide: true,
    },
  },
];
