<!-- 新闻详情 -->
<template>
  <div class="frame">
    <div class="location">
      <i class="el-icon-location-outline"></i>
      <span>您当前所在：</span>
      <BreadCrumb :breadcrumbs="routeArr" />
    </div>
    <div class="content">
      <div class="title-box">
        <div class="title">{{ detail.title }}</div>
        <div class="time">
          发布时间：{{ detail.publishTime || detail.createTime | handleTime }}
        </div>
      </div>
      <div class="detail" v-html="detail.contentStr"></div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "../components/breadCrumb.vue";
export default {
  data() {
    return {
      detail: {},
      routeArr: [
        { name: "首页", path: "/" },
        { name: "新闻中心", path: "/news" },
        { name: "新闻详情" },
      ],
    };
  },
  components: { BreadCrumb },
  created() {
    this.getDetail();
  },
  mounted() {},
  methods: {
    async getDetail() {
      let res = await this.$api.getNewDetail(this.$route.query.id);
      if (!res.data) {
        this.$notify.warning({ title: "当前内容已不存在" });
      }
      this.detail = res.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.frame {
  min-height: calc(100vh - 316px);
  margin: 10px 0 20px;

  .location {
    width: 1200px;
    margin: 10px auto;
    font-size: 14px;
    display: flex;
    align-items: center;

    span {
      margin-left: 5px;
    }
  }

  .content {
    width: 1200px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 2px;
    box-sizing: 0 0 5px #eee;

    .title-box {
      text-align: center;
      padding: 30px 20px 5px;
      line-height: 40px;
      border-bottom: 1px solid #dedede;

      .title {
        font-size: 30px;
      }
      .time {
        font-size: 14px;
        color: #999;
      }
    }
    .detail {
      min-height: 400px;
      padding: 20px;
    }
    :deep(.detail img),
    :deep(.detail video) {
      max-width: 100%;
      height: auto;
    }
  }
}

@media (max-width: 750px) {
  .frame {
    .location {
      width: 100%;
      padding: 10px 10px 0;
      box-sizing: border-box;
      margin-top: 50px;
      flex-wrap: wrap;
    }
    .content {
      width: 100%;

      .title-box {
        line-height: 35px;
        padding: 10px;

        .title {
          font-size: 25px;
        }
      }

      .detail {
        padding: 10px;
      }
    }
  }
}
</style>
