<!-- 个人信息展示组件 -->
<template>
  <div class="userinfo">
    <span>{{ userInfo.idNo }}，您好</span>
    <el-button class="red" type="text" @click="logout">退出</el-button>
  </div>
</template>

<script>
import { Dialog } from "vant";
export default {
  data() {
    return {
      userInfo: {
        idNo: "",
      },
    };
  },
  created() {
    this.userInfo.idNo = sessionStorage.getItem("userInfo");
  },
  mounted() {},
  methods: {
    logout() {
      Dialog.confirm({
        title: "提示",
        message: "确认退出登录？",
      })
        .then(() => {
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("userInfo");
          window.location.reload();
        })
        .catch(() => {
          console.log("取消");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.userinfo {
  font-size: 14px;

  span {
    margin-right: 5px;
  }
}
@media (max-width: 750px) {
  .userinfo {
    margin-top: 5px;
  }
}
</style>
