<template>
  <div class="layout">
    <Head :globalData="globalData" />
    <keep-alive>
      <router-view
        :key="$route.path"
        v-if="$route.meta.keepAlive"
      ></router-view>
    </keep-alive>
    <router-view :key="$route.path" v-if="!$route.meta.keepAlive"></router-view>
    <Foot :globalData="globalData" />
  </div>
</template>

<script>
import Head from "./components/head";
import Foot from "./components/foot";
export default {
  data() {
    return {
      globalData: {},
    };
  },
  components: {
    Head,
    Foot,
  },
  created() {
    this.$api.getConfig().then((res) => {
      this.globalData = res.data;
    });
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.layout {
  &::before {
    content: "";
    display: table;
  }
}
</style>
